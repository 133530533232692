var token

if (localStorage.getItem('token')) {
  new Promise(resolve => {
    token = `Bearer ${localStorage.getItem('token')}`
    resolve()
  }).catch(() => {
    localStorage.clear()
  })
}

export default {
  //API_BASE_URL_DEV: 'http://localhost:8000',
  API_BASE_URL_DEV: 'http://localhost:8000',

  API_BASE_URL_PROD: 'https://api.pokemon.sistemaweb.com.br',

  ACCESS_TOKEN: token
}
